import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layout'),
    children:[
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: '/en',
        name: 'enHome',
        component: () => import('@/views/en_home')
      },
      {
        path: '/meirun',
        name: 'meirun',
        component: () => import('@/views/customer/meirunCustom.vue')
      },
      {
        path: '/exhibition',
        name: 'exhibition',
        component: () => import('@/views/exhibition/index.vue')
      },
      {
        path: '/smartPark',
        name: 'smartPark',
        component: () => import('@/views/smartPark/index.vue')
      },
      {
        path: '/oee',
        name: 'oee',
        component: () => import('@/views/oee/index.vue')
      },
      {
        path: '/sac',
        name: 'sac',
        component: () => import('@/views/sac/index.vue')
      },
      {
        path: '/fap',
        name: 'fap',
        component: () => import('@/views/fap/index.vue')
      },
      {
        path: '/machining',
        name: 'machining',
        component: () => import('@/views/machining/index.vue')
      },
      {
        path: '/assemble',
        name: 'assemble',
        component: () => import('@/views/assemble/index.vue')
      },
      {
        path: '/sheetMetal',
        name: 'sheetMetal',
        component: () => import('@/views/sheetMetal/index.vue')
      },
      
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
